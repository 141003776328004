<template>
  <div class="pagedetail">
    <div class="common-header-wrap">
      <div>
        <img
          class="header-img"
          :src="require('../../assets/images/common-banner.jpg')"
          alt=""
        />
      </div>
      <div
        class="tab-list dis-flex"
        v-if="menus && menus.children && menus.children.length > 0"
      >
        <div
          class="tab-item flex-shrink0 dis-flex flex-x-center flex-y-center"
          v-for="item in menus.children"
          :key="item.id"
          @click="goPage(item)"
        >
          <span
            class="font-16"
            :class="item.id === currentId ? 'font-blue font-700' : 'font-balck'"
            >{{ item.title }}</span
          >
        </div>
      </div>
    </div>
    <div class="content" v-if="page">
      <div v-html="page.content"></div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      name: null,
      page: null,
      menus: null,
      currentId: null,
    };
  },
  beforeRouteUpdate(to, from, next) {
    if (to.name === "PageDetail") {
      this.name = to.params.name;
      this.getpage();
      this.init(to.query);
    }
    next();
  },
  methods: {
    async getpage() {
      const resp = await this.$api.get("getpage", {
        name: this.name,
      });
      if (resp.success) {
        this.page = resp.data.page;
      }
    },

    async getMenu (data) {
      const resp = await this.$api.get('getmenu', data)
      if (resp.success) {
        if (resp.data.menus.length > 0) {
          this.menus = resp.data.menus[0]
        }
      }
    },

    goPage (menu) {
      let query = {
        id: menu.id
      }
      if (menu.parent_id) {
        query.parent_id = menu.parent_id
      }
      this.$router.push({path: menu.link, query}).catch(() => {})
      this.showMenu = false
    },

    async init (query) {
      if (!query) return
      let id = query.id
      let parentId = query.parent_id
      if (id) {
        this.currentId = Number(id)
      }
      if (parentId) {
        await this.getMenu({id: parentId})
      }
    }
  },
  async mounted() {
    this.name = this.$route.params.name;
    await this.getpage();
    this.init(this.$route.query)
  },
};
</script>
